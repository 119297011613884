<template>
  <div>
    <GridMenuTop
      v-show="calibrated"
      ref="gridMenuTop"
      class="avenir pa4 vh-100" />
  </div>
</template>

<script>
import GridMenuTop from './components/GridMenuTop.vue'

export default {
  name: 'App',
  components: {
    GridMenuTop
  },
  data() {
    return {
      calibrated: false,
      showVideo : true
    }
  },
  methods: {
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {

        //Set up the main canvas. The main canvas is used to calibrate the webgazer.
        var canvas = document.getElementById("plotting_canvas");
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        canvas.style.position = 'fixed';

        window.webgazerPrecision = 0;
        window.webgazerX = -1;
        window.webgazerY = -1;

        window.webgazer
          .saveDataAcrossSessions(true)
          .begin();

        window.webgazer
          .showVideoPreview(true)
          .showPredictionPoints(true)
          .applyKalmanFilter(true);

        window.saveDataAcrossSessions = true;

        window.onbeforeunload = function() {
          window.webgazer.end();
        }

        /**
         * Restart the calibration process by clearing the local storage and reseting the calibration point
         */
        window.webgazer.clearData();
        window.ClearCalibration();
        window.PopUpInstruction();

        window.setInterval( () => {
          if (window.webgazerPrecision == undefined || window.webgazerPrecision < 50 ) {
            console.log( "Não calibrado" );
            return;
          }

          this.calibrated = true;

          window.webgazer
            .getCurrentPrediction()
            .then( (prediction) => {
              if( prediction.x && prediction.y ) {
                var x = prediction.x;
                var y = prediction.y;
                window.webgazerX = x | 0; // converte para int
                window.webgazerY = y | 0;
              }
            })
            .catch( (err) => {
              window.webgazerX = -1;
              window.webgazerY = -1;
              console.error(err);
            } );

        }, 500 );

      }
    }
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
</style>
