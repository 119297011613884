<template>
  <div class="cf bg-blue center tc x-flex x-flex-wrap">
    <!-- <BigButton text="1" @click="StartEyeTracking" /> -->
    <BigButton text=""    image="/img/feliz.png" />
    <BigButton text=""    image="/img/triste.png" />
    <BigButton text="sim" image="/img/sim.png" />
    <BigButton text="não" image="/img/nao.png" />

    <BigButton text=""    image="/img/sono.png" />
    <BigButton text=""    image="/img/nojo.png" />
    <BigButton text=""    image="/img/mal_estar.png"/>
    <BigButton text=""    image="/img/medo.png" />

    <BigButton text=""    image="/img/agua.png"/>
    <BigButton text=""    image="/img/musica.png" />
    <BigButton text=""    image="/img/beijo.png" />
    <BigButton text=""    image="/img/gripado.png"/>

    <BigButton text=""    image="/img/casa.png" />
    <BigButton text=""    image="/img/calor.png" />
    <BigButton text=""    image="/img/sol.png" />
    <BigButton text=""    image="/img/dor.png"/>
  </div>
</template>

<script>
import BigButton from '@/components/BigButton.vue'

export default {
  name: 'GridMenuTop',
  components: {
    BigButton
  },
  data() {
    return {
      show6: true
    }
  },
  setup () {
  },
  methods: {
    StartEyeTracking() {
      //console.log( "StartEyeTracking" );
      //window.GazeCloudAPI.StartEyeTracking();
    },
    StopEyeTracking() {
      //console.log( "StopEyeTracking" );
      //window.GazeCloudAPI.StopEyeTracking();
    },
    Hide6() {
      this.show6 = false;
    }
  }
}
</script>

