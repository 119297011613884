<template>
  <div class="w-25 h-25 pa2 dib">
    <div class="bg-near-white pa3 h-100 flex items-center br4 shadow-4" :class="style">
      <div class="f1 w-100">
        {{ text }} {{ counter }}
        <img v-if="image" :src="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BigButton',
  props: {
    text: String,
    image: String,
  },
  setup () {
  },
  data() {
    return {
      counter: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: 0,
      height: 0,
      intervalid1: null,
      style: '',
      x: -2,
      y: -2
    };
  },
  methods: {
    // isSelected() {
    //   console.log( "X: ", window.webgazerX, " L: ", this.left );
    //   if( window.webgazerX > this.left &&
    //      window.webgazerX < this.right &&
    //      window.webgazerY > this.top &&
    //      window.webgazerY < this.bottom ) {
    //     this.counter += 1;
    //     return "bg-red";
    //   }
    //   this.counter = 0;
    //   return "";
    // }

    // todo: function(){  
    // this.intervalid1 = setInterval(() => {
    //    this.changes = ((Math.random() * 100).toFixed(2))+'%';
    //    console.log (this.changes);
    //}, 3000);
    //}

  },
  mounted() {

    this.intervalid1 = setInterval(
      () => {

        this.top    = this.$el.getBoundingClientRect().top    | 0;
        this.bottom = this.$el.getBoundingClientRect().bottom | 0;
        this.left   = this.$el.getBoundingClientRect().left   | 0;
        this.right  = this.$el.getBoundingClientRect().right  | 0;
        this.width  = this.$el.getBoundingClientRect().width  | 0;
        this.height = this.$el.getBoundingClientRect().height | 0;

        // window.webgazerX = window.webgazerX;
        // window.webgazerY = window.webgazerY;
        // console.log( this.text, window.webgazerX, window.webgazerY );

        if( window.webgazerX > this.left &&
          window.webgazerX < this.right &&
          window.webgazerY > this.top &&
          window.webgazerY < this.bottom ) {
          this.counter += 1;

          if( this.counter > 20 ) {
            this.counter = 0;
            this.$el.click();
          }
          else if( this.counter > 18 ) {
            this.style = "bg-orange";
          }
          else if( this.counter > 12 ) {
            this.style = "bg-light-red";
          }
          else if( this.counter > 5 ) {
            this.style = "bg-light-yellow";
          } else {
            this.style = "bg-light-blue";
          }
        } else {
          this.style = "";
          this.counter = 0;
        }
      }, 100);
  }
}
</script>

<style scoped>
  img {
    max-height: 10vh;
  }
</style>
